class ZntCursor {
	constructor() {
		this.zntCursor = document.createElement("div");
		this.zntPointer = document.createElement("div");
		this.zntFollower = document.createElement("div");
		this.zntArticle = document.createElement("div");
		this.zntDrag = document.createElement("div");
		this.zntPlay = document.createElement("div");
		this.currentX = null;
		this.currentY = null;
		this.pointerCircle = null;
		this.followerCircle = null;
		this.hasInitialized = false;

		const methods = Object.getOwnPropertyNames(Object.getPrototypeOf(this));
		methods
			.filter(method => method !== "constructor")
			.forEach(method => {
				this[method] = this[method].bind(this);
			});
	}

	init() {
		this.zntCursor.id = "cursor-wrapper";
		this.zntPointer.id = "cursor-pointer";
		this.zntFollower.id = "cursor-follower";
		this.zntPlay.id = "cursor-play";
		this.zntArticle.id = "cursor-article";
		this.zntArticle.innerHTML = "<div><div>Read article</div></div>";
		this.zntDrag.id = "cursor-drag";
		this.zntDrag.innerHTML =
			"<div><div><div class='click-n'>Click n’</div> drag</div></div>";
	}

	start() {
		if (window.innerWidth > 1024) {
			if (!this.hasInitialized) {
				this.addCursor();

				this.currentX = window.innerWidth / 2;
				this.currentY = window.innerHeight / 2;

				this.pointerCircle = {
					lastX: this.currentX,
					lastY: this.currentY,
					size: this.zntPointer.getBoundingClientRect(),
				};

				this.followerCircle = {
					lastX: this.currentX,
					lastY: this.currentY,
					size: this.zntFollower.getBoundingClientRect(),
				};
			}

			this.hasInitialized = true;

			this.addEvents();
			this.render();
		}
	}

	destroy() {
		this.zntCursor.classList.remove("active", "news", "link", "drag", "click", "video");
	}

	addCursor() {
		this.zntCursor.appendChild(this.zntPointer);
		this.zntCursor.appendChild(this.zntFollower);
		this.zntFollower.appendChild(this.zntArticle);
		this.zntPointer.appendChild(this.zntDrag);
		this.zntPointer.appendChild(this.zntPlay);
		document.body.appendChild(this.zntCursor);
	}

	removeCursor() {
		this.zntCursor.remove();
	}

	updatePosition(x, y) {
		this.currentX = x;
		this.currentY = y;
	}

	lerp(from, to, ease) {
		return (1 - ease) * from + ease * to;
	}

	circleMovement(circle, ease, direction) {
		if (direction === "x") {
			return this.lerp(circle.lastX, this.currentX, ease);
		}

		return this.lerp(circle.lastY, this.currentY, ease);
	}

	render() {
		this.pointerCircle.lastX = this.circleMovement(this.pointerCircle, 1, "x");
		this.pointerCircle.lastY = this.circleMovement(this.pointerCircle, 1, "y");
		this.zntPointer.style.transform = `translate(${
			this.pointerCircle.lastX - this.pointerCircle.size.width / 2
		}px, ${this.pointerCircle.lastY - this.pointerCircle.size.height / 2}px)`;

		this.followerCircle.lastX = this.circleMovement(
			this.followerCircle,
			0.3,
			"x",
		);
		this.followerCircle.lastY = this.circleMovement(
			this.followerCircle,
			0.3,
			"y",
		);
		this.zntFollower.style.transform = `translate(${
			this.followerCircle.lastX - this.followerCircle.size.width / 2
		}px, ${this.followerCircle.lastY - this.followerCircle.size.height / 2}px)`;
		requestAnimationFrame(this.render);
	}

	addEvents() {
		window.addEventListener("pointermove", e =>
			this.updatePosition(e.clientX, e.clientY),
		);

		document.body.addEventListener("mouseenter", this.showCursor);
		document.body.addEventListener("mouseleave", this.hideCursor);

		document.querySelectorAll("a").forEach(link => {
			link.addEventListener("mouseover", this.linkEnter);
			link.addEventListener("mouseout", this.linkLeave);
		});

		document.querySelectorAll("section.projects .item a, .social a").forEach(link => {
			link.addEventListener("mouseover", this.linkOutEnter);
			link.addEventListener("mouseout", this.linkOutLeave);
		});

		document
			.querySelectorAll(
				"button, .btn, section.investors ul li, .wpcf7-list-item",
			)
			.forEach(button => {
				button.addEventListener("mouseover", this.buttonEnter);
				button.addEventListener("mouseout", this.buttonLeave);
			});

		document.querySelectorAll(".list-inner").forEach(eco => {
			eco.addEventListener("mouseover", this.ecoEnter);
			eco.addEventListener("mouseout", this.ecoLeave);

			eco.addEventListener("mousedown", this.ecoClick);
			eco.addEventListener("mouseup", this.ecoClickOut);
		});

		document.querySelectorAll(".grid .item, .featured .item").forEach(news => {
			news.addEventListener("mouseover", this.newsEnter);
			news.addEventListener("mouseout", this.newsLeave);
		});

		document.querySelectorAll(".video").forEach(video => {
			video.addEventListener("mouseover", this.videoEnter);
			video.addEventListener("mouseout", this.videoLeave);
		});
	}

	removeEvents() {
		window.removeEventListener("pointermove", e =>
			this.updatePosition(e.clientX, e.clientY),
		);

		document.body.removeEventListener("mouseenter", this.showCursor);
		document.body.removeEventListener("mouseleave", this.hideCursor);

		document.querySelectorAll("a").forEach(link => {
			link.removeEventListener("mouseover", this.linkEnter);
			link.removeEventListener("mouseout", this.linkLeave);
		});

		document.querySelectorAll("section.projects .item a, .social a").forEach(link => {
			link.removeEventListener("mouseover", this.linkOutEnter);
			link.removeEventListener("mouseout", this.linkOutLeave);
		});

		document
			.querySelectorAll(
				"button, .btn, section.investors ul li, .wpcf7-list-item",
			)
			.forEach(button => {
				button.removeEventListener("mouseover", this.buttonEnter);
				button.removeEventListener("mouseout", this.buttonLeave);
			});

		document.querySelectorAll(".list-inner").forEach(eco => {
			eco.removeEventListener("mouseover", this.ecoEnter);
			eco.removeEventListener("mouseout", this.ecoLeave);

			eco.removeEventListener("mousedown", this.ecoClick);
			eco.removeEventListener("mouseup", this.ecoClickOut);
		});

		document.querySelectorAll(".grid .item, .featured .item").forEach(news => {
			news.removeEventListener("mouseover", this.newsEnter);
			news.removeEventListener("mouseout", this.newsLeave);
		});

		document.querySelectorAll(".video").forEach(video => {
			video.removeEventListener("mouseover", this.videoEnter);
			video.removeEventListener("mouseout", this.videoLeave);
		});
	}

	showCursor() {
		this.zntCursor.style.display = "block";
	}

	hideCursor() {
		this.zntCursor.style.display = "none";
	}

	linkEnter() {
		this.zntCursor.classList.add("active");
	}
	linkLeave() {
		this.zntCursor.classList.remove("active");
	}

	linkOutEnter() {
		this.zntCursor.classList.add("link");
	}
	linkOutLeave() {
		this.zntCursor.classList.remove("link");
	}

	buttonEnter() {
		this.zntCursor.classList.add("hide");
	}
	buttonLeave() {
		this.zntCursor.classList.remove("hide");
	}

	ecoEnter() {
		this.zntCursor.classList.add("drag");
	}
	ecoLeave() {
		this.zntCursor.classList.remove("drag");
	}

	ecoClick() {
		this.zntCursor.classList.add("click");
	}
	ecoClickOut() {
		this.zntCursor.classList.remove("click");
	}

	newsEnter() {
		this.zntCursor.classList.add("news");
	}
	newsLeave() {
		this.zntCursor.classList.remove("news");
	}

	videoEnter() {
		this.zntCursor.classList.add("video");
	}
	videoLeave() {
		this.zntCursor.classList.remove("video");
	}
}

export default ZntCursor;
