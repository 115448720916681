import Swiper, { Autoplay } from "swiper";

function swiperEcosystem() {
  new Swiper("section.ecosystem .swiper", {
    modules: [Autoplay],
    loop: true,
    slidesPerView: 1,
    speed: 5000,
    autoplay: {
      delay: 1,
    },
    freeMode: true,

    breakpoints: {
      768: {
        slidesPerView: 2,
      },
    },
  });
}

export { swiperEcosystem };
